import React, { useContext, useEffect } from 'react';

import { useFacebookLandingText } from '@src/hooks/directusHooks/useFacebookLandingText';
import SocialNetworkV2FreePage from '@src/components/social-network-free-page/social-network-v2';
import couponCodeService from '@src/utils/couponCodeService';
import { trackEvent } from '@src/services/tracking/tracking';

// START: Vodafone redirect hack
import { ERROR_MESSAGES } from '@src/constants';
import { useQueryParamValue } from '@hooks/useQueryParams';
import { GlobalContext } from '@store/global-state';
// END: Vodafone redirect hack

const lp3_70 = () => {
  const { landing, home } = useFacebookLandingText();
  const { setOffer } = couponCodeService();

  // START: Vodafone redirect hack
  const { setErrorModalMessage } = useContext(GlobalContext);
  const sourceIsVodafone = useQueryParamValue('utm_source') == "vodafone";
  // END: Vodafone redirect hack

  const items = [
    {
      title: 'Take your health quiz',
      description: 'Tell us about your diet & lifestyle in just 5 minutes.',
    },
    {
      title: 'Get your personalised pack',
      description: 'We’ll create your pack based on your answers.',
    },
    {
      title: 'Get 70% off your 1st month',
      description: 'Get your personalised vitamins. Change or cancel your plan anytime.',
    },
  ];

  const text = {
    ...landing,
    ...home,
    hero_text: 'Choose vitamins personalised to you and get',
    hero_focus: '70% off your 1st month today ',
    hero_focus_price: null,
    how_it_work_header: 'How it works',
    how_it_works_items_list: items,
    hero_price_element: '<b>1st Pack | <s>£39.95</s> <span>£11.98</span></b>',
    sticky_banner_text: 'Take Quiz & get 70% off',
  };

  useEffect(() => {
    setOffer('4pp', '70off');
    trackEvent('result_70off_view');
      // START: Vodafone redirect hack
      if (sourceIsVodafone) {
        window.alert(ERROR_MESSAGES.freeTrialOfferLimitReached70PercentRedirect);
        // setErrorModalMessage(ERROR_MESSAGES.freeTrialOfferLimitReached70PercentRedirect);
      }
      // END: Vodafone redirect hack
  }, []);

  return <SocialNetworkV2FreePage text={text} noindex={true} />;
};

export default lp3_70;
